<template>
    <div class="m-0 p-0 row position-relative">
        <div class="col-12 p-0">
            <sp-navbar></sp-navbar>
        </div>

        <!-- Header image -->

        <div class="col-12 p-0 position-relative" id="inicio">
            <sp-slider _id="container_1" 
                :items="[header_image1, header_image2, header_image3]"
                :show_dots="false"
                :autoplay="true"
                :time_autoplay="5000">

            </sp-slider>    

            <!-- <img :src="header_image" class="w-100" /> -->

            <div class="position-fixed" style="right: 0px; bottom: 20%; z-index: 15;">
                <ul class="list-unstyled">
                    <li
                        class="p-0 hover_effect bg-dark " 
                        style="width: 45px; margin: 2px 0; height: 45px">
                        <a href="#contacto"
                            class="w-100 h-100 d-flex align-items-center justify-content-center">
                            <b-icon icon="wallet-fill" class="p-1"
                                scale="2"
                                variant="white">
                            </b-icon>
                        </a>
                    </li>
                    <li
                        class="p-0 hover_effect" 
                        style="width: 45px; margin: 2px 0;">
                        <a href="tel:5543276724">
                            <img :src="call_us" class="img-fluid" />
                        </a>    
                    </li>
                    <li
                        class="p-0 hover_effect" 
                        style="width: 45px; margin: 2px 0;">
                        <a href="https://api.whatsapp.com/send?phone=+525549917455&text=Hola%20Simepox" target="_blank" >
                            <img :src="wt" class="img-fluid" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Nuestra empresa -->

        <div class="col-12 p-0">
            <sp-separator title="Nuestra Empresa" id="nuestra_empresa"
                variant="danger">
            </sp-separator>
        </div>
        <div class="col-12 p-0">
            <div class="row flex-center py-5">
                <div data-aos="fade-up" :data-aos-delay="(300) + (100 * index)"
                    class="col-12 col-md-10 my-1" 
                    v-for="(card, index) in cards" :key="index">

                        <sp-card 
                            :img="card.img"
                            :title="card.title"
                            :description="card.description">
                        </sp-card>
                </div>    
            </div>    
        </div>
        
        

        <!-- Sistemas especializados -->
        <div class="col-12 px-0 py-2 py-md-0" id="sistemas_especializados" >
            <sp-carousel _id="carousel_system"
                :item="especialized_systems"
                title="Sistemas Especializados"
                :variant="1"
                @openModal="fillModal"
                :buttons_navigation="true"
                :show_next_item="false">
                
            </sp-carousel>
        </div>

        <!-- Ingeneria -->

        <div class="col-12 px-0 py-2 py-md-0" id="ingenieria">
            <sp-carousel _id="carousel_ingenery"
                :item="ingeniery"
                title="Ingeniería"
                :variant="2"
                :buttons_navigation="true"
                :show_next_item="false">
                
            </sp-carousel>
        </div>

        <!-- Hoteleria -->

        <div class="col-12 px-0 py-2 py-md-0" id="hoteleria">
            <sp-carousel _id="carousel_hotelery"
                :item="hotelery"
                title="Hotelería"
                :variant="1"
                @openModal="fillModal">
                
            </sp-carousel>
        </div>

        <!-- Galeria -->

        <div class="col-12 p-0">
            <sp-separator title="Galería" id="galeria"
                variant="danger">
            </sp-separator>
        </div>
        <div class="col-12 p-0">
            <carousel id="asqw" :perPageCustom="[[280, 2], [768, 4]]" 
                :adjustableHeight="true"
                :paginationEnabled="false"
                :autoplay="true"
                :loop="true"
                class="py-5 px-2">

                <slide data-name="SlideGallery"
                    :data-index="g_index"
                    v-for="(g_value, g_index) in galery" :key="g_index"
                    class="w-50 w-md-25">

                    <div class="row" style="min-height: 345px">
                        <div class="col-12 flex-center px-1">
                            <img :src="g_value"
                                class="img-fluid">
                        </div>
                    </div>
                    
                </slide>
            </carousel>
        </div>

        <!-- Footer -->
        <div class="col-12 p-0">
            <sp-separator title="Contacto" id="contacto"
                variant="gray">
            </sp-separator>
        </div>
        <div class="col-12 p-0">
            <sp-footer></sp-footer>
        </div>

        <!-- Modal -->
        <sp-modal v-if="detail_modal">
            <div class="row bg-white position-relative" 
                style="max-height: 90vh; max-width: 90%; height: 100%; border-radius: 15px; overflow-y: scroll;">

                <button @click.stop="detail_modal = false"
                    class="btn btn-white border border-danger p-0 position-absolute"
                    style="border-radius: 50%; width: 30px; height: 30px; z-index: 10; top: 5px; right: 5px;">
                    X
                </button>
                <div class="col-12 pt-5">
                    <sp-gallery :title="gallery_title"
                        :description="gallery_description"
                        :gallery_images="gallery_images">

                    </sp-gallery>
                </div>
            </div>
        </sp-modal>

        <!-- <div class="col-12 p-0">
            <sp-slider _id="container_1" 
                :items="[1,2,3,4,5]"
                :show_dots="true"
                :autoplay="false">

            </sp-slider>    
        </div> -->
        
        
    </div>
</template>

<script>
    //components
    import SPNavbar from 'COMPONENTS/NavbarOf.vue'
    import SPCard from 'COMPONENTS/Card2.vue'
    import SPSeparator from 'COMPONENTS/Separator.vue'
    import SPCarousel from 'COMPONENTS/Carousel.vue'
    import SPFooter from 'COMPONENTS/FooterSP.vue'
    import { Carousel, Slide } from 'vue-carousel'

    //images
    import sp_simepox from 'ASSETS/images/our_company/rec_1'
    import sp_company from 'ASSETS/images/our_company/rec_2'
    import sp_innovation from 'ASSETS/images/our_company/rec_3'
    import sp_nature from 'ASSETS/images/our_company/rec_4'
    import sp_policy from 'ASSETS/images/our_company/rec_5'
    import sp_logo from 'ASSETS/images/our_company/logo_simepox.png'

    //header lateral navbar
    import header_image1 from 'ASSETS/images/slider_main/1'
    import header_image2 from 'ASSETS/images/slider_main/2'
    import header_image3 from 'ASSETS/images/slider_main/3'

    import calendar from 'ASSETS/images/header/calendar.png'
    import call_us from 'ASSETS/images/header/call_us.png'
    import notes from 'ASSETS/images/header/notes.png'
    import wt from 'ASSETS/images/header/whatsapp.png'

    // JSON
    import { especialized_systems } from 'ASSETS/json/especialized_systems.js'
    import { ingeniery } from 'ASSETS/json/ingeniery.js'
    import { hotelery } from 'ASSETS/json/hotelery.js'
    import { galery } from 'ASSETS/json/galery.js'

    export default {
        components: {
            'sp-navbar': SPNavbar,
            'sp-card': SPCard,
            'sp-separator': SPSeparator,
            'sp-carousel': SPCarousel,
            'sp-footer': SPFooter,
            Carousel,
            Slide
        },
        data() {
            return {
                sp_logo,
                header_image1, header_image2, header_image3,
                notes,
                call_us,
                wt,
                cards: [ 
                    {
                        img: sp_simepox,
                        title: 'Simepox',
                        description: 'Grupo de Construcción Simepox es una Compañía especializada con mas de 20 años de experiencia en la   instalación y mantenimiento de pisos, muros y recubrimientos  de alto desempeño, con sistemas diseñados para las empresas que requieren alcanzar las más estrictas normas ecológicas y de calidad CERTIFICADO (ISO, FDA, NSF),  brindamos apoyo técnico especializado en ingeniería y laboratorio para una solución  integral de su proyecto,  ajustándonos tanto a sus horarios,  normativas y especificaciones de proyectos presentes y futuros.'
                    },
                    {
                        img: sp_company,
                        title: 'Perfil de la Empresa',
                        description: 'Contamos con la experiencia, infraestructura, calidad y el mejor costo para las necesidades que su proyecto requiera, realizando trabajos a las empresas más importantes del país y a nivel mundial, con personal altamente capacitado, con el equipo y herramienta especializado para ejecutar su proyecto.'
                    },
                    {
                        img: sp_innovation,
                        title: 'Innovación',
                        description: 'Estamos comprometidos con la calidad a través de un programa permanente de mejora continua, con alta tecnología en nuestros laboratorios innovando materiales y acabados de alta gama, basado en la capacitación e involucramiento de nuestro personal que es capacitado continuamente con cursos de calidad, seguridad e higiene avalados por nuestros clientes e instituciones para lograr de tal forma que nuestros clientes siempre estén seguros de recibir un trabajo profesional y de alta calidad.'
                    },
                    {
                        img: sp_nature,
                        title: 'Medio Ambiente',
                        description: `Grupo simepox está comprometido con el liderazgo en materia medioambiental en todos los aspectos de su negocio. Grupo Simepox dedica tiempo implementando políticas corporativas para proporcionar un lugar de trabajo seguro y saludable, para proteger el medio ambiente, conservar la energía y los recursos naturales. <br>
                            Estas políticas han sido formalizadas siendo de gran utilidad para el medio ambiente como para los proyectos ejecutados.`
                    },
                    {
                        img: sp_policy,
                        title: 'Política HSE y código de conducta',
                        description: `Grupo Simepox esta comprometido a proporcionar un lugar de trabajo seguro y saludable llevando a cabo las actividades del negocio de tal manera que se proteja al medio ambiente.
                            Para alcanzar estas metas, todos los establecimientos de la compañía deben adherirse a los principios siguientes:
                            Identificación y evaluación de todos los peligrosos aspectos de HSE y la administración de esos riesgos para reducir sus impactos a niveles aceptables.
                            Cumplimiento con toda la legislación aplicable de HSE.
                            Prevención de incidentes, lesiones y contaminación.
                            Intolerancia de las condiciones y conductas que contribuyan a los incidentes y lesiones.
                            Reducción de desechos y conservación de recursos.
                            Registro y comunicación del desempeño en HSE a través de la organización.
                            Mejora continua del desempeño en HSE.`
                    }
                ],
                especialized_systems,
                ingeniery,
                hotelery,
                galery,
                detail_modal: false,
                modal_content: '',
                gallery_title: null,
                gallery_description: null,
                gallery_images: null
            }
        },
        methods: {
            fillModal(data) {
                console.log(data, 'data')
                this.detail_modal = true
                this.gallery_title = data.title
                this.gallery_description = data.description
                this.gallery_images = JSON.parse(data.gallery_images)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    .w-md-25 {
        @include upPixels(768px) {
            width: 25% !important;
        }
    }

    .hover_effect{
        transition: all 0.4s;
    }
    .hover_effect:hover {
        outline: 1px solid red;
        box-shadow: 0px 0px 15px red;
    }
</style>